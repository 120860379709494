<template>
	<div class="total-paid-amount-banner">
		<span class="total-label">{{ $t('total') }}</span>
		<span class="total-value">{{ paidAmountFormatted }}</span>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'PaymentDetailsTotalBanner',
	mixins: [IdMixin],
	props: {
		paidAmountFormatted: {
			type: String,
			required: true
		}
	}
})
export default class PaymentDetailsTotalBanner extends Vue {}
</script>

<style lang="scss" scoped>
.total-paid-amount-banner {
	width: 100%;
	background-color: $blue-dark;
	color: white;
	border-radius: 5px;
	border: 1px solid $gray-light;
	padding-top: 12px;
	padding-right: 15px;
	padding-bottom: 12px;
	padding-left: 15px;
	margin-bottom: 30px;
}
.total-label {
	font-family: $font-family-headline;
	font-size: 18px;
}
.total-value {
	font-size: 15px;
	float: right;
	font-weight: bold;
}
</style>

<i18n>
{
  "en": {
	"total": "Total"
  },
  "fr": {
	"total": "Total"
  }
}
</i18n>
