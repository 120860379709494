<template>
	<PaymentHistoryTemplate
		v-if="paymentDetails"
		:page-title="$t('title')"
		:full-width="true"
		:payment-details="true"
	>
		<PaymentDetailsInformation
			:issue-date="paymentDetails.issueDate"
			:issued-to-name="paymentDetails.issuedToName"
			:payment-type="paymentDetails.paymentType"
			:paid-amount-formatted="paidAmountFormatted"
			:address="paymentDetails.address"
			automation-id="PaymentHistoryDetailsPage"
		/>
		<CalculationOfBenefits
			v-for="(calculationOfBenefits, index) in paymentDetails.calculationOfBenefits"
			:key="calculationOfBenefits.participantId"
			:calculation-of-benefits="calculationOfBenefits"
			:first-of-list="index === 0"
			:class="getCobClass(index)"
		/>
		<template v-if="paymentDetails.claimAdjustments.adjustmentLines.length > 0">
			<ClaimAdjustments
				v-for="(claimAdjustment, index) in paymentDetails.claimAdjustments"
				:key="index"
				:claim-adjustments="claimAdjustment"
			/>
		</template>
		<PaymentDetailsSubTotalBanner
			v-if="paymentDetails.subtotals"
			:subtotals="paymentDetails.subtotals"
		/>
		<ExplanationOfBenefitsAndHSA
			v-if="hsaExists(paymentDetails.hsaExplanationOfBenefits)"
			:explanation-of-benefits-or-h-s-a="paymentDetails.hsaExplanationOfBenefits"
			:eob="false"
		/>
		<PaymentDetailsTotalBanner :paid-amount-formatted="paidAmountFormatted" />
		<BaseButton
			:id="getUniqueId('backButton')"
			class="pl-0 mr-3"
			:automation-id="getAutomationId('backButton')"
			:label="$t('button.back')"
			:aria-label="$t('button.back')"
			:icon="['fal', 'chevron-left']"
			icon-position="left"
			variant="link"
			@click="navigateBack()"
		></BaseButton>
		<BaseButton
			class="payment-history-pdf-button"
			variant="primary"
			:label="$t('button.download')"
			:aria-label="$t('button.download')"
			pill
			is-right
			:icon="['fal', 'print']"
			icon-position="left"
			automation-id="paymentSearchResultsPdfDownload"
			:data-tracking-id="'payment-search-results-pdf-download'"
			@click="getPaymentHistoryDetailsPdf()"
		></BaseButton>
		<BaseCollapse
			id="payment-history-details-page-collapse"
			:button-label="$t('collapse.button')"
			button-variant="link"
			:visible="isVisible"
			:aria-expanded="isVisible"
			class="payment-history-details-collapse"
			@input="changeIsVisible"
		>
			<span> {{ $t('collapse.text') }} </span>
		</BaseCollapse>
	</PaymentHistoryTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import PaymentDetailsInformation from '@/components/payment-history/PaymentDetailsInformation.vue';
import CalculationOfBenefits from '@/components/payment-history/CalculationOfBenefits.vue';
import ClaimAdjustments from '@/components/payment-history/ClaimAdjustments.vue';
import PaymentDetailsTotalBanner from '@/components/payment-history/PaymentDetailsTotalBanner.vue';
import PaymentHistoryTemplate from '@/pages/payment-history/PaymentHistoryTemplate.vue';
import ExplanationOfBenefitsAndHSA from '@/components/payment-history/ExplanationOfBenefitsAndHSA.vue';
import PaymentDetailsSubTotalBanner from '@/components/payment-history/PaymentDetailsSubTotalBanner.vue';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCollapse from '@/components/common/base/BaseCollapse';
import PaymentDetails from '@/models/PaymentDetailsScepter';
import { DASHBOARD } from '@/constants/Routes.js';

// @vue/component
@Component({
	name: 'PaymentHistoryDetailsPage',
	components: {
		PaymentDetailsInformation,
		PaymentHistoryTemplate,
		CalculationOfBenefits,
		ClaimAdjustments,
		PaymentDetailsTotalBanner,
		BaseButton,
		BaseCollapse,
		ExplanationOfBenefitsAndHSA,
		PaymentDetailsSubTotalBanner
	},
	mixins: [IdMixin],
	watch: {
		locale() {
			if (!this.isEmptyResults) {
				this.dataLangChange();
			}
		}
	}
})
export default class PaymentHistoryDetailsPage extends Vue {
	isVisible = false;
	paymentDetails = null;

	get locale() {
		return this.$root.$i18n.locale;
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get paidAmountFormatted() {
		if (this.isEnglish) {
			return Number(this.paymentDetails.paidAmount).toLocaleString('en-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		} else {
			return Number(this.paymentDetails.paidAmount).toLocaleString('fr-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		}
	}

	async beforeMount() {
		this.paymentDetails = await PaymentDetails.getSavedSearchResults(
			sessionStorage.getItem('email')
		);
		if (!this.paymentDetails?.calculationOfBenefits) {
			this.navigateBack();
		}
	}

	async dataLangChange() {
		let searchCriterias = await PaymentDetails.getSavedSearchParameters(
			sessionStorage.getItem('email')
		);

		if (searchCriterias) {
			this.paymentDetails = await PaymentDetails.getPaymentDetails(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				searchCriterias
			);
		} else {
			this.navigateBack();
		}
	}

	hsaExists(hsa) {
		return hsa && hsa.length > 0;
	}

	navigateBack() {
		if (this.$route?.params?.isClaims) {
			this.$router.push({ name: DASHBOARD, params: { isClaims: true } });
		} else {
			this.$router.go(-1);
		}
	}
	changeIsVisible() {
		this.isVisible = !this.isVisible;
	}
	getCobClass(index) {
		if (
			this.paymentDetails.calculationOfBenefits &&
			index !== this.paymentDetails.calculationOfBenefits.length - 1
		) {
			return 'particpant-sections';
		}
		return '';
	}

	async getPaymentHistoryDetailsPdf() {
		let paymentHistoryPdfSearchCriteria = null;

		var tempSearchCriteria =
			(await PaymentDetails.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
		if (!tempSearchCriteria?.paymentId) {
			this.navigateBack();
		} else {
			paymentHistoryPdfSearchCriteria = tempSearchCriteria;
		}

		this.$store.dispatch('updateLoading', true);
		let pdfByte = await PaymentDetails.getPaymentDetailsPDF(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			paymentHistoryPdfSearchCriteria
		);
		if (pdfByte) {
			var titleString = this.$t('title');
			var filename = titleString.replaceAll(' ', '-');
			this.downloadPaymentHistoryPdf(pdfByte, filename);
		} else {
			this.$store.dispatch('error');
		}
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	downloadPaymentHistoryPdf(data, filename) {
		if (navigator.appVersion.toString().indexOf('.NET') > 0)
			window.navigator.msSaveBlob(new Blob([data]), `Identification.pdf`);
		else {
			const demoMode = sessionStorage.getItem('demoActive');
			let fileURL = require('@/assets/blank-demo-document.pdf');
			if (!demoMode) {
				fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			}
			const fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);

			fileLink.click();
		}
	}
}
</script>

<style lang="scss" scoped>
.payment-history-details-collapse {
	margin-top: 30px;
	margin-bottom: 30px;
}
.particpant-sections {
	margin-bottom: 35px;
}
</style>

<i18n>
{
  "en": {
	"title": "Payment Details",
	"button": {
		"download": "Download",
		"back": "Back to results"
	},
	"collapse": {
		"button": "Something missing from your claim details?",
		"text": "Not all reductions to claim payouts are displayed. Sometimes your claim payments might be reduced based on things like eligible expenses, coordination of benefits (payouts to your spouse's plan) or payment made directly to your provider."
	}
  },
  "fr": {
	"title": "Détails sur le paiement",
	"button": {
		"download": "Télécharger",
		"back": "Retour aux résultats"
	},
	"collapse": {
		"button": "Il manque quelque chose dans les détails de votre demande de règlement?",
		"text": "Les réductions aux paiements des demandes de règlement ne sont pas toutes affichées. Parfois, le paiement de votre demande est réduit en fonction d’éléments tels que les dépenses admissibles, la coordination des prestations (paiements faits au régime de votre conjoint ou conjointe) ou le paiement effectués directement à votre fournisseur."
	}
  }
}
</i18n>
