<!--
  Page template to be use for all Manage Your Account pages.
-->
<template>
	<div class="margin-top">
		<BRow v-if="pageTitle">
			<BCol>
				<h1 class="mb-3">{{ pageTitle }}</h1>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" :lg="fullWidth ? '12' : '10'">
				<slot></slot>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';

export default {
	name: 'PaymentHistoryTemplate',
	components: { BRow, BCol },
	mixins: [IdMixin, BreadcrumbsManager],

	props: {
		pageTitle: {
			type: String,
			description: 'Top of page title',
			default: null
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		paymentDetails: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			paymentHistoryCrumbsEN: [
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.paymentHistory', 'en'),
					to: { name: Routes.PAYMENT_HISTORY }
				}
			],
			paymentHistoryCrumbsFR: [
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.paymentHistory', 'fr'),
					to: { name: Routes.PAYMENT_HISTORY }
				}
			],
			paymentDetailsCrumbsEN: [
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.paymentHistory', 'en'),
					to: { name: Routes.PAYMENT_HISTORY }
				},
				{
					text: this.$t('breadcrumb.paymentDetails', 'en'),
					to: { name: Routes.PAYMENT_HISTORY }
				}
			],
			paymentDetailsCrumbsFR: [
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{
					text: this.$t('breadcrumb.paymentHistory', 'fr'),
					to: { name: Routes.PAYMENT_HISTORY }
				},
				{
					text: this.$t('breadcrumb.paymentDetails', 'fr'),
					to: { name: Routes.PAYMENT_HISTORY }
				}
			]
		};
	},
	created() {
		var englishCrumbs = this.paymentHistoryCrumbsEN;
		var frenchCrumbs = this.paymentHistoryCrumbsFR;
		if (this.paymentDetails) {
			englishCrumbs = this.paymentDetailsCrumbsEN;
			frenchCrumbs = this.paymentDetailsCrumbsFR;
		}
		this.setBreadcrumbPath(englishCrumbs, frenchCrumbs);
	}
};
</script>

<style lang="scss" scoped>
.margin-top {
	margin-top: 15px !important;
	@media (min-width: 768px) {
		margin-top: 30px !important;
	}
}
</style>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
	  "paymentHistory": "Payment history",
	  "paymentDetails": "Payment Details"
    }
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
	  "paymentHistory": "Historique des paiements",
	  "paymentDetails": "Détails sur le paiement"
    }
  }
}
</i18n>
