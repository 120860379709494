<template>
	<BaseCard :class="eobClass">
		<p class="eob-title">{{ title() }}</p>
		<p
			v-for="(item, index) in explanationOfBenefitsOrHSA"
			:key="index"
			:class="eobOrHSAClass(index)"
		>
			{{ item }}
		</p>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard.vue';

// @vue/component
@Component({
	name: 'ExplanationOfBenefitsAndHSA',
	components: {
		BaseCard
	},
	mixins: [IdMixin],
	props: {
		explanationOfBenefitsOrHSA: {
			type: Array,
			required: true
		},
		eob: {
			type: Boolean,
			default: true
		},
		hsaExists: {
			type: Boolean,
			default: false
		}
	}
})
export default class ExplanationOfBenefitsAndHSA extends Vue {
	thingy = null;

	get eobClass() {
		return this.eob && this.hsaExists ? 'eob-desc-component-two' : 'eob-desc-component';
	}

	eobOrHSAClass(index) {
		return this.explanationOfBenefitsOrHSA?.length === index + 1 ? 'last-eob-descs' : 'eob-descs';
	}
	title() {
		return this.eob ? this.$t('eobTitle') : this.$t('hsaTitle');
	}
}
</script>

<style lang="scss" scoped>
.eob-title {
	font-family: $font-family-headline;
	font-size: 17px;
	margin: 0px;
	margin-bottom: 7px;
}
.eob-descs {
	font-size: 17px;
	margin-top: 0px;
	margin-bottom: 12px;
}
.last-eob-descs {
	font-size: 17px;
	margin: 0px;
}
.eob-desc-component {
	//Override card margin
	margin-bottom: 20px !important;
	border-radius: 0px;
}
.eob-desc-component ::v-deep .card-body {
	padding: 15px;
}
.eob-desc-component-two {
	//Override card margin
	margin-bottom: 10px !important;
	border-radius: 0px;
}
.eob-desc-component-two ::v-deep .card-body {
	padding: 15px;
}
</style>

<i18n>
{
  "en": {
	"eobTitle": "Explanation of Benefits",
	"hsaTitle": "Spending Account"
  },
  "fr": {
	"eobTitle": "Description de la garantie",
	"hsaTitle": "Compte de depenses"
  }
}
</i18n>
