<template>
	<BaseTable
		thead-class="d-none"
		:items="getFooterData()"
		:fields="getColumns()"
		stacked="lg"
		borderless
		class="base-table-styles"
		show-empty
	>
		<template #cell(subtotal)>
			<span class="d-none d-lg-block">{{ $t('column.subtotal') }}</span>
		</template>
		<template #cell(submittedAmount)>
			<div>
				<!-- for lg screens need to add hidden label for screen readers only -->
				<label class="visually-hidden">{{ $t('column.submittedAmount') }}</label>
				<span>{{ submittedAmountTotal }}</span>
			</div>
		</template>
		<template #cell(eligibleAmount)>
			<div>
				<label class="visually-hidden">{{ $t('column.eligibleAmount') }}</label>
				<span>{{ eligibleAmountTotal }}</span>
			</div>
		</template>
		<template #cell(excludedAmount)>
			<div>
				<label class="visually-hidden">{{ $t('column.excludedAmount') }}</label>
				<span>{{ excludedAmountTotal }}</span>
			</div>
		</template>
		<template #cell(deductibleAmount)>
			<div>
				<label class="visually-hidden">{{ $t('column.deductibleAmount') }}</label>
				<span>{{ deductibleAmountTotal }}</span>
			</div>
		</template>
		<template #cell(paidAmount)>
			<div>
				<label class="visually-hidden">{{ $t('column.paidAmount') }}</label>
				<span>{{ paidAmountTotal }}</span>
			</div>
		</template>
		<template #cell(hspAmount)>
			<div>
				<label class="visually-hidden">{{ $t('column.hspAmount') }}</label>
				<span>{{ hspAmountTotal }}</span>
			</div>
		</template>
	</BaseTable>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseTable from '@/components/common/base/BaseTable';

@Component({
	name: 'PaymentDetailsTotalBanner',
	components: {
		BaseTable
	},
	props: {
		subtotals: {
			type: Object,
			required: true
		}
	},
	mixins: [IdMixin]
})
export default class PaymentDetailsTotalBanner extends Vue {
	getFooterData() {
		return [
			{
				subtotal: this.$t('column.subtotal'),
				submittedAmountTotal: this.formatAmount(this.subtotals.totalSubmittedAmount),
				eligibleAmountTotal: this.formatAmount(this.subtotals.totalEligibleAmount),
				excludedAmountTotal: this.formatAmount(this.subtotals.totalExcludedAmount),
				deductibleAmountTotal: this.formatAmount(this.subtotals.totalDeductibleAmount),
				paidAmountTotal: this.formatAmount(this.subtotals.totalPaidAmount),
				hspAmountTotal: this.formatAmount(this.subtotals.totalHspAmount)
			}
		];
	}

	get submittedAmountTotal() {
		return this.formatAmount(this.subtotals.totalSubmittedAmount);
	}

	get eligibleAmountTotal() {
		return this.formatAmount(this.subtotals.totalEligibleAmount);
	}

	get excludedAmountTotal() {
		return this.formatAmount(this.subtotals.totalExcludedAmount);
	}

	get deductibleAmountTotal() {
		return this.formatAmount(this.subtotals.totalDeductibleAmount);
	}

	get paidAmountTotal() {
		return this.formatAmount(this.subtotals.totalPaidAmount);
	}

	get hspAmountTotal() {
		return this.formatAmount(this.subtotals.totalHspAmount);
	}

	formatAmount(amount) {
		if (this.isEnglish) {
			return Number(amount).toLocaleString('en-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		} else {
			return Number(amount).toLocaleString('fr-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		}
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	/**
	 * Returns the list of columns
	 * @returns {array}
	 */
	getColumns() {
		return [
			{
				key: 'subtotal',
				label: this.$t('column.subtotal'),
				thClass: 'th-subtotal-class',
				tdClass: 'td-subtotal-class'
			},
			{
				key: 'submittedAmount',
				label: this.$t('column.submittedAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'eligibleAmount',
				label: this.$t('column.eligibleAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'excludedAmount',
				label: this.$t('column.excludedAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'deductibleAmount',
				label: this.$t('column.deductibleAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'paidAmount',
				label: this.$t('column.paidAmount'),
				thClass: 'th-paid-class',
				tdClass: 'td-paid-class'
			},
			{
				key: 'hspAmount',
				label: this.$t('column.hspAmount'),
				thClass: 'th-spending-account-class',
				tdClass: 'td-spending-account-class'
			}
		];
	}
}
</script>

<style lang="scss" scoped>
.base-table-styles {
	border-collapse: collapse;
	background-color: #ffffff;
}
.base-table-styles ::v-deep .td-amount-class div {
	font-weight: 300;
}
.base-table-styles ::v-deep td {
	border: none;
	font-size: 15px;
	font-family: $font-family-regular;
	@include media-breakpoint-up(lg) {
		width: 12.5%;
	}
	@include media-breakpoint-down(md) {
		padding: 0.3rem;
	}
}
.base-table-styles ::v-deep td:first-child {
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	border: none;
	border-bottom: 0 !important;
}
.base-table-styles ::v-deep td:last-child {
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border: none;
	border-bottom: 0 !important;
}
.base-table-styles.b-table-stacked-lg {
	& ::v-deep tr:first-child {
		background-color: #e4e4e4;
		border: none;
		border-radius: 5px;
		@include media-breakpoint-down(md) {
			padding: 0;
		}
	}
	&::v-deep tbody tr [data-label='Total'] div {
		display: none;
	}
	&:last-child {
		border-radius: 5px;
	}
	&:not(:first-child) {
		border-left: none;
	}
	& ::v-deep td:first-child::before {
		font-size: 18px;
	}
}
.base-table-styles ::v-deep .td-subtotal-class {
	font-size: 18px;
	font-family: $font-family-headline;
	padding: 13px 10px 11px 15px;
	font-weight: bold;
}
.base-table-styles ::v-deep .th-amount-class {
	text-align: right;
	vertical-align: text-top;
}
.base-table-styles ::v-deep .td-amount-class {
	text-align: right;
	@include media-breakpoint-down(md) {
		text-align: left;
	}
	@include media-breakpoint-up(lg) {
		line-height: 1.8;
	}
}
.base-table-styles ::v-deep .th-paid-class {
	text-align: right;
	vertical-align: text-top;
}
.base-table-styles ::v-deep .td-paid-class {
	text-align: right;
	font-weight: bold;
	@include media-breakpoint-down(md) {
		text-align: left;
	}
	@include media-breakpoint-up(lg) {
		line-height: 1.8;
		width: 25%;
	}
}
.base-table-styles ::v-deep .th-spending-account-class {
	@include media-breakpoint-up(lg) {
		text-align: right;
		padding: 10px 15px 10px 10px;
	}
}
.base-table-styles ::v-deep .td-spending-account-class {
	font-weight: bold;
	@include media-breakpoint-up(lg) {
		text-align: right;
		padding: 10px 15px 10px 10px;
		line-height: 1.8;
	}
}
.visually-hidden {
	position: absolute;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@include media-breakpoint-down(md) {
	::v-deep td > div {
		width: 50% !important;
	}
	.visually-hidden {
		display: none;
	}
}

@include media-breakpoint-down(md) {
	::v-deep td::before {
		position: relative;
		top: 2px;
		font-family: $font-family-headline;
		width: 50% !important;
	}
}
</style>
<i18n>
{
  "en": {
	"column": {
		"subtotal": "Subtotal",
		"submittedAmount": "Submitted Amount",
		"eligibleAmount": "Eligible Amount",
		"excludedAmount": "Excluded Amount",
        "deductibleAmount": "Deductible",
		"paidAmount": "Blue Cross Paid",
		"hspAmount": "Spending Account Paid"
	}
  },
  "fr": {
	"column": {
		"subtotal": "Sous-total",
		"submittedAmount": "Montant soumis",
		"eligibleAmount": "Montant admissible",
		"excludedAmount": "Montant exclu",
        "deductibleAmount": "Franchise",
		"paidAmount": "Croix Bleue a payé",
		"hspAmount": "Payé par le compte Gestion-santé"
    }
  }
}
</i18n>
