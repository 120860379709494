<template>
	<PaymentHistoryTemplate :page-title="$t('title')">
		<BaseAlert class="alert mb-4" show variant="warning" alternate>
			<template #description>
				<i18n path="alert.text" tag="span">
					<template #claimsHistory>
						<router-link :to="{ name: claimHistoryLink }">{{ $t('alert.link') }}</router-link>
					</template>
				</i18n>
			</template>
		</BaseAlert>
		<BaseCollapse
			:button-label="$t('button.collapse')"
			button-variant="primary"
			:icon-two="['fal', 'search']"
			:visible="isVisible"
			class="payment-history-collapse"
			@input="changeIsVisible"
		>
			<StatementSearchFields
				automation-id="paymentHistoryPageFields"
				@click="updateSearchCriteriaAndGetData"
			/>
		</BaseCollapse>
		<StatementSearchResults
			v-if="!isEmptyResults"
			automation-id="paymentHistoryPageResults"
			:date-range-string="dateRangeString"
			:statement-search-results="paymentHistory.paymentLines"
			:total-amount="paymentHistory.totalPaidAmount"
			class="payment-history-result-table"
			:show-h-s-p="paymentHistory.showHSP"
			@details="goToDetailsPage"
		/>
		<BaseButton
			v-if="!isEmptyResults"
			class="payment-history-pdf-button"
			variant="primary"
			:label="$t('button.download')"
			pill
			is-right
			:icon="['fal', 'print']"
			icon-position="left"
			automation-id="paymentSearchResultsPdfDownload"
			:data-tracking-id="'payment-search-results-pdf-download'"
			@click="getPaymentHistoryPdf()"
		></BaseButton>
		<ErrorModal
			:error-title="$t('modal.title')"
			:error-message="$t('modal.message')"
			:has-close-button="false"
			:modal-show="showErrorModal"
			@change="closeErrorModal"
		>
			<BaseButton
				class="mt-2"
				:label="$t('button.ok')"
				:aria-label="$t('button.ok')"
				variant="primary"
				automation-id="noPaymentHistoryButton"
				@click="closeErrorModal"
			/>
		</ErrorModal>
	</PaymentHistoryTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
// mixins
import IdMixin from '@/mixins/id';
// components
import BaseAlert from '@/components/common/alert/BaseAlert';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCollapse from '@/components/common/base/BaseCollapse';
import ErrorModal from '@/components/common/ErrorModal';
import StatementSearchFields from '@/components/payment-history/StatementSearchFields.vue';
import StatementSearchResults from '@/components/payment-history/StatementSearchResults.vue';
// pages
import PaymentHistoryTemplate from '@/pages/payment-history/PaymentHistoryTemplate.vue';
// models
import PaymentHistory from '@/models/PaymentHistory';
import PaymentDetails from '@/models/PaymentDetailsScepter';
// constants
import { CONTACT_US } from '@/constants/Routes.js';
import { PAYMENT_HISTORY_DETAILS, CLAIM_HISTORY } from '@/constants/Routes.js';
//utils
import { format } from 'date-fns';

@Component({
	name: 'PaymentHistoryPage',
	components: {
		PaymentHistoryTemplate,
		StatementSearchFields,
		StatementSearchResults,
		BaseButton,
		BaseCollapse,
		ErrorModal,
		BaseAlert
	},
	mixins: [IdMixin],
	watch: {
		locale() {
			if (!this.isEmptyResults) {
				this.updateSearchCriteriaAndGetDataLangChange(this.searchCriteria);
			}
		}
	}
})
export default class PaymentHistoryPage extends Vue {
	isEmptyResults = true;
	showErrorModal = false;
	paymentHistory = [];
	searchCriteria = {
		fromDate: null,
		toDate: null
	};
	dateRangeString = '';
	isVisible = true;

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get locale() {
		return this.$root.$i18n.locale;
	}

	get claimHistoryLink() {
		return CLAIM_HISTORY;
	}

	async created() {
		this.paymentHistory =
			(await PaymentHistory.getSavedSearchResults(sessionStorage.getItem('email'))) || [];
		this.isEmptyResults = !this.paymentHistory?.paymentLines?.length || false;
		if (this.isEmptyResults) {
			this.searchCriteria = {
				fromDate: format(
					new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
					'yyyy-MM-dd'
				),
				toDate: this.getMaxDate()
			};
			this.$store.dispatch('updateLoading', true);
			this.paymentHistory = await PaymentHistory.getPaymentHistory(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				this.searchCriteria
			);
			this.$store.dispatch('updateLoading', false);
			this.isEmptyResults = !this.paymentHistory?.paymentLines?.length || false;
		}
		this.createDateRangeString();
	}

	closeErrorModal() {
		this.showErrorModal = false;
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	async createDateRangeString() {
		var localeString = 'en-GB';
		var options = { day: '2-digit', month: 'short', year: 'numeric' };
		if (!this.isEnglish) {
			localeString = 'fr-FR';
		}
		if (this.searchCriteria?.fromDate) {
			const fromDate = this.createDate(this.searchCriteria.fromDate, options, localeString);
			const toDate = this.createDate(this.searchCriteria.toDate, options, localeString);
			this.dateRangeString = `${fromDate} ${this.$t('subtitle.to')} ${toDate}`;
		} else {
			var tempSearchCriteria =
				(await PaymentHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
			if (tempSearchCriteria?.fromDate) {
				const fromDate = this.createDate(tempSearchCriteria.fromDate, options, localeString);
				const toDate = this.createDate(tempSearchCriteria.toDate, options, localeString);
				this.dateRangeString = `${fromDate} ${this.$t('subtitle.to')} ${toDate}`;
			} else {
				this.dateRangeString = '';
			}
		}
	}

	createDate(date, options, localeString) {
		const formattedDate = new Date(
			date.split('-')[0],
			date.split('-')[1] - 1,
			date.split('-')[2]
		).toLocaleDateString(localeString, options);
		return formattedDate;
	}

	async updateSearchCriteriaAndGetData(searchCriteria) {
		this.$store.dispatch('updateLoading', true);
		if (searchCriteria) {
			this.searchCriteria = searchCriteria;
			this.createDateRangeString();
			this.paymentHistory = await PaymentHistory.getPaymentHistory(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				this.searchCriteria
			);
		}
		// Check if any claims found
		this.isEmptyResults = !this.paymentHistory?.paymentLines?.length || false;

		if (this.isEmptyResults) {
			this.showErrorModal = true;
		}
		this.$store.dispatch('updateLoading', false);
	}

	async updateSearchCriteriaAndGetDataLangChange(searchCriteria) {
		this.$store.dispatch('updateLoading', true);
		if (searchCriteria?.fromDate) {
			this.searchCriteria = searchCriteria;
			this.createDateRangeString();
			this.paymentHistory = await PaymentHistory.getPaymentHistory(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				this.searchCriteria
			);
		} else {
			var tempSearchCriteria =
				(await PaymentHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};

			if (tempSearchCriteria?.fromDate) {
				this.searchCriteria = tempSearchCriteria;
				this.createDateRangeString();
				this.paymentHistory = await PaymentHistory.getPaymentHistory(
					sessionStorage.getItem('email'),
					sessionStorage.getItem('apiToken'),
					this.$root.$i18n.locale,
					this.searchCriteria
				);
			}
		}
		// Check if any claims found
		this.isEmptyResults = !this.paymentHistory?.paymentLines?.length || false;

		if (this.isEmptyResults) {
			this.showErrorModal = true;
		}
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * Handle on click event.
	 */
	async getPaymentHistoryPdf() {
		let paymentHistoryPdfSearchCriteria = null;
		if (!this.searchCriteria?.toDate) {
			var tempSearchCriteria =
				(await PaymentHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
			if (!tempSearchCriteria?.toDate) {
				paymentHistoryPdfSearchCriteria = {
					fromDate: format(
						new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
						'yyyy-MM-dd'
					),
					toDate: this.getMaxDate()
				};
			} else {
				paymentHistoryPdfSearchCriteria = tempSearchCriteria;
			}
		} else {
			paymentHistoryPdfSearchCriteria = this.searchCriteria;
		}

		this.$store.dispatch('updateLoading', true);
		let pdfByte = await PaymentHistory.getPaymentHistoryPDF(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			paymentHistoryPdfSearchCriteria
		);
		if (pdfByte) {
			var titleString = this.$t('title');
			var filename = titleString.replaceAll(' ', '-');
			this.downloadPaymentHistoryPdf(pdfByte, filename);
		} else {
			this.$store.dispatch('error');
		}
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	downloadPaymentHistoryPdf(data, filename) {
		if (navigator.appVersion.toString().indexOf('.NET') > 0)
			window.navigator.msSaveBlob(new Blob([data]), `Identification.pdf`);
		else {
			const demoMode = sessionStorage.getItem('demoActive');
			let fileURL = require('@/assets/blank-demo-document.pdf');
			if (!demoMode) {
				fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			}
			const fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);

			fileLink.click();
		}
	}

	changeIsVisible() {
		this.isVisible = !this.isVisible;
	}

	navigateToContactUs() {
		this.$router.push({ name: CONTACT_US });
	}

	async goToDetailsPage(item) {
		this.$store.dispatch('updateLoading', true);
		let detailsSearchCriteria;
		if (this.searchCriteria?.fromDate) {
			detailsSearchCriteria = {
				paymentId: item.paymentId,
				fromDate: this.searchCriteria.fromDate,
				toDate: this.searchCriteria.toDate
			};
		} else {
			var tempSearchCriteria =
				(await PaymentHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
			if (tempSearchCriteria?.fromDate) {
				detailsSearchCriteria = {
					paymentId: item.paymentId,
					fromDate: tempSearchCriteria.fromDate,
					toDate: tempSearchCriteria.toDate
				};
			} else {
				detailsSearchCriteria = {
					paymentId: item.paymentId,
					fromDate: format(
						new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
						'yyyy-MM-dd'
					),
					toDate: this.getMaxDate()
				};
			}
		}
		let paymentDetails = await PaymentDetails.getPaymentDetails(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			detailsSearchCriteria
		);
		this.$store.dispatch('updateLoading', false);
		if (paymentDetails) {
			this.$router.push({ name: PAYMENT_HISTORY_DETAILS });
		} else {
			this.$store.dispatch('error');
		}
	}
}
</script>
<style lang="scss" scoped>
.alert {
	font-family: $lato-font-family;
}
.statement-contact-us-button {
	padding: 0px;
}
.payment-history-collapse {
	margin-bottom: 30px;
}
.payment-history-result-table {
	margin-bottom: 30px;
}
.payment-history-pdf-button {
	margin-bottom: 30px;
}
</style>

<i18n>
{
  "en": {
	"subtitle": {
		"to": "to"
	},
    "title": "Payment History",
    "alert": {
		"text": "This page shows payments processed as of the last business day. Not all payments are displayed. See the {claimsHistory} page for prescription drug claims filed through your pharmacy, and all claims older than 2 years.",
		"link": "Claims History"
	},
    "button": {
		"download": "Download",
		"collapse": "Search your statements",
		"ok": "OK"
    },
	"modal": {
		"title": "No results found.",
		"message": "We couldn't find anything matching your request. Please try  a new search."
	}

  },
  "fr": {
	"subtitle": {
		"to": "au"
	},
    "title": "Historique des paiements",
    "alert": {
		"text": "Cette page affiche les paiements traités en date du plus récent jour ouvrable. Les paiements ne sont pas tous affichés. Consultez la page {claimsHistory} de règlement pour voir les demandes de règlement de médicaments sur ordonnance soumises par votre pharmacie, ainsi que toutes les demandes de règlement qui datent de plus de deux ans.",
		"link": "Historique des demandes"
	},
    "button": {
        "download": "Télécharger",
		"collapse": "Cherchez vos relevés",
		"ok": "OK"
    },
	"modal": {
		"title": "Aucun résultat trouvé.",
		"message": "Nous n'avons rien trouvé qui corresponde à votre demande. Veuillez effectuer une nouvelle recherche."
	}
  }
}
</i18n>
