<template>
	<BaseCard class="card-styles">
		<div class="payment-information-title">
			<span>
				{{ $t('paymentInfo.header') }}
			</span>
		</div>
		<div class="payment-information-data">
			<BRow class="mb-1">
				<BCol cols="6">
					<span class="data-column-header">
						{{ $t('paymentInfo.dateProcessed') }}
						<InfoModal
							class="date-processed-button-body"
							automation-id="date-processed-popup"
							:modal-title="$t('modal.title')"
						>
							{{ $t('modal.text') }}
						</InfoModal>
					</span>
				</BCol>
				<BCol cols="6">
					<span class="data-column-data">
						{{ issueDateFormatted }}
					</span>
				</BCol>
			</BRow>
			<BRow class="mb-1">
				<BCol cols="6">
					<span class="data-column-header">
						{{ $t('paymentInfo.paidTo') }}
					</span>
				</BCol>
				<BCol cols="6">
					<span class="data-column-data">
						{{ issuedToName }}
					</span>
				</BCol>
			</BRow>
			<BRow class="mb-1">
				<BCol cols="6">
					<span class="data-column-header">
						{{ $t('paymentInfo.paymentType') }}
					</span>
				</BCol>
				<BCol cols="6">
					<span class="data-column-data">
						{{ paymentType }}
					</span>
				</BCol>
			</BRow>
			<BRow v-if="address">
				<BCol cols="6">
					<span class="data-column-header">
						{{ $t('paymentInfo.sentTo') }}
					</span>
				</BCol>
				<BCol cols="6">
					<span class="data-column-data">
						<BRow v-if="address.addressLine1">
							<BCol cols="12">
								{{ address.addressLine1 }}
							</BCol>
						</BRow>
						<BRow v-if="address.addressLine2">
							<BCol cols="12">
								{{ address.addressLine2 }}
							</BCol>
						</BRow>
						<BRow v-if="address.city">
							<BCol cols="12">
								{{ address.city }}
							</BCol>
						</BRow>
						<BRow v-if="address.province">
							<BCol cols="12">
								{{ address.province }}
							</BCol>
						</BRow>
						<BRow v-if="address.postalCode">
							<BCol cols="12">
								{{ address.postalCode }}
							</BCol>
						</BRow>
					</span>
				</BCol>
			</BRow>
		</div>
		<div class="payment-information-amount">
			<BRow>
				<BCol cols="6">
					<span>
						{{ $t('paymentInfo.paymentAmount') }}
					</span>
				</BCol>
				<BCol cols="6">
					<span>
						{{ paidAmountFormatted }}
					</span>
				</BCol>
			</BRow>
		</div>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard.vue';
import { BRow, BCol } from 'bootstrap-vue';
import InfoModal from '@/components/common/InfoModal';

// @vue/component
@Component({
	name: 'PaymentDetailsInformation',
	components: {
		BaseCard,
		InfoModal,
		BRow,
		BCol
	},
	mixins: [IdMixin],
	props: {
		issueDate: {
			type: String,
			required: true
		},
		issuedToName: {
			type: String,
			required: true
		},
		paymentType: {
			type: String,
			required: true
		},
		paidAmountFormatted: {
			type: String,
			required: true
		},
		address: {
			type: Object,
			required: true
		}
	}
})
export default class PaymentDetailsInformation extends Vue {
	beforeMount() {}

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get issueDateFormatted() {
		var newDate =
			this.issueDate.slice(0, 4) + '/' + this.issueDate.slice(4, 6) + '/' + this.issueDate.slice(6);
		const dateProcessed = new Date(newDate);
		var options = { day: '2-digit', month: 'short', year: 'numeric' };

		if (this.isEnglish) {
			return dateProcessed.toLocaleDateString('en-GB', options);
		} else {
			return dateProcessed.toLocaleDateString('fr-FR', options);
		}
	}
}
</script>

<style lang="scss" scoped>
.card-styles {
	width: 50%;
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}
::v-deep .card-body {
	padding: 0px;
}
.payment-information-title {
	background-color: $gray-slightly-lighter;
	padding: 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-family: $font-family-headline;
	font-size: 24px;
}
.payment-information-data {
	padding: 10px;
}
.data-column-header {
	font-family: $font-family-headline;
	font-size: 18px;
}
.data-column-data {
	font-family: $font-family-regular;
	font-size: 17px;
}
.payment-information-amount {
	background-color: $blue-dark;
	color: white;
	padding: 10px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	font-family: $font-family-headline;
	font-size: 18px;
}
.date-processed-button-body {
	padding: 0px;
	display: inline;
}
</style>

<i18n>
{
  "en": {
    "paymentInfo" : {
        "header" : "Payment Information",
        "dateProcessed": "Date Processed",
        "paidTo": "Paid to",
        "paymentType": "Payment Type",
		"paymentAmount": "Payment Amount",
		"sentTo": "Sent to"
	},
	"modal" : {
		"title": "What does this mean?",
		"text": "'Date processed' refers to the date that we processed your submitted claim and issued your payment. If you've chosen to be reimbursed through direct deposit, it can take up to 7 business days for your bank to deposit your money."
	}
  },
  "fr": {
    "paymentInfo" : {
        "header" : "Renseignements sur le paiement",
        "dateProcessed": "Date de traitement",
        "paidTo": "Payé à",
        "paymentType": "Type de paiement",
		"paymentAmount": "Montant du paiement",
		"sentTo": "Envoyer à"
    },
	"modal" : {
		"title": "Qu'est-ce que cela signifie?",
		"text": "La « date de traitement » est la date à laquelle nous avons traité votre demande de règlement et émis votre paiement. Si vous avez opté pour le remboursement par dépôt direct, il faut compter jusqu'à 7 jours ouvrables pour que votre banque dépose votre argent."
	}
  }
}
</i18n>
