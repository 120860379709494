<template>
	<BaseCard class="eoa-desc-component">
		<p class="eoa-title">{{ $t('eoaTitle') }}</p>
		<p v-for="(item, index) in explanationOfAdjustment" :key="index" :class="eoaClass(index)">
			{{ item }}
		</p>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard.vue';

// @vue/component
@Component({
	name: 'ExplanationOfAdjustment',
	components: {
		BaseCard
	},
	mixins: [IdMixin],
	props: {
		explanationOfAdjustment: {
			type: Array,
			required: true
		}
	}
})
export default class ExplanationOfAdjustment extends Vue {
	eoaClass(index) {
		return this.explanationOfAdjustment?.length === index + 1 ? 'last-eoa-descs' : 'eoa-descs';
	}
}
</script>

<style lang="scss" scoped>
.eoa-title {
	font-family: $font-family-headline;
	font-size: 17px;
	margin: 0px;
	margin-bottom: 7px;
}
.eoa-descs {
	font-size: 17px;
	margin-top: 0px;
	margin-bottom: 12px;
}
.last-eoa-descs {
	font-size: 17px;
	margin: 0px;
}
.eoa-desc-component ::v-deep .card-body {
	padding: 15px;
}
.eoa-desc-component {
	margin-bottom: 20px !important;
	border-radius: 0px;
}
</style>

<i18n>
{
  "en": {
	"eoaTitle": "Explanation of Adjustment"
  },
  "fr": {
	"eoaTitle": "Explication du rajustement"
  }
}
</i18n>
