<template>
	<div>
		<BRow class="name-header pt-2 m-0">
			<BCol cols="12" class="header">{{ tableHeaderText() }}</BCol>
			<BCol cols="12" class="ben-desc">{{ benefitDescription }} </BCol>
		</BRow>
		<BaseTable
			:items="prepareData()"
			stacked="lg"
			:fields="getColumns()"
			small
			class="base-table-styles"
		>
			<template #head(excludedAmount)="data">
				<span class="text-info">{{ data.label }}</span>
				<InfoModal
					class="excluded-amount-info-button-header"
					automation-id="excluded-amount-popup"
					:modal-title="$t('modal.excludedTitle')"
				>
					{{ $t('modal.excludedText') }}
				</InfoModal>
			</template>
			<template #head(coinsurancePct)="data">
				<span>{{ data.label }}</span>
				<InfoModal
					class="excluded-amount-info-button-header"
					automation-id="excluded-amount-popup"
					:modal-title="$t('modal.coinsurancePctTitle')"
				>
					{{ $t('modal.coinsurancePctText') }}
				</InfoModal>
			</template>
			<template #cell(excludedAmount)="row">
				<span>{{ row.value }}</span>
				<InfoModal
					class="excluded-amount-info-button-body"
					automation-id="excluded-amount-popup"
					:modal-title="$t('modal.excludedTitle')"
				>
					{{ $t('modal.excludedText') }}
				</InfoModal>
			</template>
			<template #cell(coinsurancePct)="row">
				<span>{{ row.value }}</span>
				<InfoModal
					class="excluded-amount-info-button-body"
					automation-id="excluded-amount-popup"
					:modal-title="$t('modal.coinsurancePctTitle')"
				>
					{{ $t('modal.coinsurancePctText') }}
				</InfoModal>
			</template>
		</BaseTable>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BTr, BTd, BTh, BTable, BRow, BCol } from 'bootstrap-vue';
import BaseTable from '@/components/common/base/BaseTable';
import BaseButton from '@/components/common/base/BaseButton';
import InfoModal from '@/components/common/InfoModal';
import DisplayCurrency from '@/components/common/DisplayCurrency';

@Component({
	name: 'PaymentDetailsTable',
	components: {
		BaseTable,
		BTr,
		BTd,
		BTh,
		BRow,
		BCol,
		BTable,
		BaseButton,
		InfoModal,
		DisplayCurrency
	},
	props: {
		paymentLine: {
			type: Object,
			default: () => {},
			required: true
		},
		benefitDescription: {
			type: String,
			required: true
		},
		isClaimAdjustment: {
			type: Boolean,
			default: false
		}
	},
	mixins: [IdMixin]
})
export default class PaymentDetailsTable extends Vue {
	emptyRowText = 'Empty row. ligne vide.';
	isModalVisible = false;

	tableHeaderText() {
		if (this.isClaimAdjustment) {
			return this.$t('adjustmentDescriptionHeader');
		} else {
			return this.$t('benefitDescriptionHeader');
		}
	}

	prepareData() {
		const preparedData = [];
		const options = { day: '2-digit', month: 'short', year: 'numeric' };
		const dateLocale = !this.isEnglish ? 'fr-CA' : 'en-CA';
		let serviceDate;
		let serviceDateTableValue;

		if (this.paymentLine.serviceDate) {
			serviceDate = new Date(`${this.paymentLine.serviceDate.slice(0, 4)}/
							 		${this.paymentLine.serviceDate.slice(4, 6)}/
							 		${this.paymentLine.serviceDate.slice(6)}`);
		}

		if (serviceDate) {
			serviceDateTableValue = serviceDate.toLocaleDateString(
				!this.isEnglish ? 'fr-FR' : 'en-GB',
				options
			);
		} else {
			serviceDateTableValue = this.$t('nA');
		}

		preparedData.push({
			id: preparedData.length + 1,
			serviceDate: serviceDateTableValue,
			submittedAmount: Number(this.paymentLine.submittedAmount).toLocaleString(dateLocale, {
				style: 'currency',
				currency: 'CAD'
			}),
			eligibleAmount: Number(this.paymentLine.eligibleAmount).toLocaleString(dateLocale, {
				style: 'currency',
				currency: 'CAD'
			}),
			excludedAmount: Number(this.paymentLine.excludedAmount).toLocaleString(dateLocale, {
				style: 'currency',
				currency: 'CAD'
			}),
			deductibleAmount: Number(this.paymentLine.deductibleAmount).toLocaleString(dateLocale, {
				style: 'currency',
				currency: 'CAD'
			}),
			coinsurancePct: `${this.paymentLine.coinsurancePct}${!this.isEnglish ? ' %' : '%'}`,
			paidAmount: Number(this.paymentLine.paidAmount).toLocaleString(dateLocale, {
				style: 'currency',
				currency: 'CAD'
			}),
			hspAmount: Number(this.paymentLine.hspAmount).toLocaleString(dateLocale, {
				style: 'currency',
				currency: 'CAD'
			})
		});

		return preparedData;
	}

	getDetails(item) {
		this.$emit('details', item);
	}

	/**
	 * Returns the list of columns
	 * @returns {array}
	 */
	getColumns() {
		return [
			{
				key: 'serviceDate',
				label: this.$t('column.serviceDate'),
				thClass: 'th-date-class',
				tdClass: 'td-date-class'
			},
			{
				key: 'submittedAmount',
				label: this.$t('column.submittedAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'eligibleAmount',
				label: this.$t('column.eligibleAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'excludedAmount',
				label: this.$t('column.excludedAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'deductibleAmount',
				label: this.$t('column.deductibleAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'coinsurancePct',
				label: this.$t('column.coinsurancePct'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'paidAmount',
				label: this.$t('column.paidAmount'),
				thClass: 'th-amount-class',
				tdClass: 'td-amount-class'
			},
			{
				key: 'hspAmount',
				label: this.$t('column.hspAmount'),
				thClass: 'th-spending-account-class',
				tdClass: 'td-spending-account-class'
			}
		];
	}

	// Gets the locale to set a watch to see if member changed language.
	get locale() {
		return this.$store.state.i18n.locale;
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	totalAmountFormatted(amount) {
		if (this.isEnglish) {
			return Number(amount).toLocaleString('en-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		} else {
			return Number(amount).toLocaleString('fr-CA', {
				style: 'currency',
				currency: 'CAD'
			});
		}
	}

	showInfoPopup() {
		this.isModalVisible = true;
	}

	changeModalVisibility(visibility) {
		this.isModalVisible = visibility;
	}
}
</script>

<style lang="scss" scoped>
.table-header {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	height: 50px;
	color: $white;
}
.header-name {
	font-size: 21px;
	font-family: $font-family-headline;
}
.header-dob {
	font-size: 17px;
	font-family: $font-family-regular;
	font-weight: 200;
	float: right;
}
.table-blank {
	width: 100%;
	height: 20px;
	background-color: $gray-very-light;
	color: $gray-very-light;
}
.table-footer ::v-deep tr {
	background-color: $gray-very-light;
}
.base-table-styles ::v-deep tfoot tr {
	padding-top: 13px;
	padding-right: 13px;
	padding-left: 13px;
	padding-bottom: 11px;
}
.base-table-styles ::v-deep tr:nth-child(even) {
	background-color: $gray-very-light;
}

.base-table-styles {
	border-collapse: collapse;
	background-color: #ffffff;
}
.base-table-styles ::v-deep th {
	font-size: 15px;
	font-family: $font-family-headline;
	font-weight: 300;
	width: 12.5%;
}
.base-table-styles ::v-deep td {
	font-size: 15px;
	font-family: $font-family-regular;
	font-weight: 300;
}
.base-table-styles ::v-deep .table-footer th {
	font-size: 15px;
	font-family: $font-family-regular;
	height: 40px;
	padding-top: 13px;
	padding-right: 13px;
	padding-left: 13px;
	padding-bottom: 11px;
	font-weight: bold;
}
.base-table-styles ::v-deep .table-footer-grand th {
	font-size: 15px;
	font-family: $font-family-regular;
	height: 40px;
	padding-top: 13px;
	padding-right: 13px;
	padding-left: 13px;
	padding-bottom: 11px;
	background-color: $blue-dark;
	color: white;
	margin-top: 20px;
	font-weight: bold;
}
.base-table-styles ::v-deep tbody tr [data-label]::before {
	font-size: 15px;
	font-family: $font-family-headline;
	font-weight: 300;
	position: relative;
	top: 3px;
}
// Amount column
.base-table-styles ::v-deep .th-amount-class {
	text-align: right;
	vertical-align: text-top;
}
.base-table-styles ::v-deep .th-date-class {
	padding: 10px 10px 10px 15px;
	vertical-align: text-top;
	text-align: left;
}
.base-table-styles ::v-deep .th-spending-account-class {
	@include media-breakpoint-up(lg) {
		text-align: right;
		padding: 10px 15px 10px 10px;
	}
}
.base-table-styles ::v-deep .td-spending-account-class {
	@include media-breakpoint-up(lg) {
		text-align: right;
		padding: 10px 15px 10px 10px;
	}
}
.base-table-styles ::v-deep .td-amount-class {
	text-align: right;
	@include media-breakpoint-down(md) {
		text-align: left;
	}
}
// Details column
.base-table-styles ::v-deep .td-details-class {
	text-align: left;
}
.top-paragraph {
	font-family: $font-family-headline;
	font-size: 21px;
	margin-bottom: 10px;
}
.bottom-paragraph {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
}
.date-range-and-total-amount {
	margin-bottom: 20px;
}
.results-for-text {
	margin: 0px;
	font-family: $font-family-headline;
	font-size: 21px;
}
.statement-search-date-range {
	float: left;
	@media (max-width: 550px) {
		float: none;
		display: block;
	}
}
.statement-search-paid-amount {
	font-weight: 500;
	float: right;
	@media (max-width: 550px) {
		float: none;
		display: block;
	}
}
.table-footer ::v-deep th:first-child {
	border-radius: 0 0 0 5px;
}
.table-footer ::v-deep th:last-child {
	border-radius: 0 0 5px 0;
}
.table-footer-grand ::v-deep th:first-child {
	border-radius: 5px 0 0 0;
}
.table-footer-grand ::v-deep th:last-child {
	border-radius: 0 5px 0 0;
}
.name-header {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	height: 100%;
	color: $white;
}
.header {
	font-size: 17px;
	font-family: $font-family-regular;
	padding-top: 5px;
	margin-bottom: 9px;
	@include media-breakpoint-up(md) {
		padding-bottom: 0px;
	}
}
.ben-desc {
	font-size: 18px;
	font-family: $font-family-headline;
	padding-bottom: 10px;
}
.excluded-amount-info-button {
	padding: 0px;
}
.excluded-amount-info-button-header {
	padding: 0px;
	display: inline;
	@media (max-width: 768px) {
		display: none;
	}
}
.excluded-amount-info-button-footer {
	padding: 0px;
	display: inline;
	@media (min-width: 768px) {
		display: none;
	}
}
.excluded-amount-info-button-body {
	padding: 0px;
	display: inline;
	@media (min-width: 768px) {
		display: none;
	}
}
.base-table-styles ::v-deep .th-benefit-desc-class {
	@media (min-width: 768px) {
		width: 200px;
	}
}
.base-table-styles ::v-deep .tr-benefit-desc-class {
	@media (min-width: 768px) {
		width: 200px;
	}
}
@include media-breakpoint-down(md) {
	::v-deep td::before,
	::v-deep td > div {
		width: 50% !important;
	}
}
</style>

<i18n>
{
  "en": {
	"benefitDescriptionHeader" : "Benefit Description",
	"adjustmentDescriptionHeader": "Adjustment Description",
	"column": {
		"serviceDate": "Service Date",
		"submittedAmount": "Submitted Amount",
		"eligibleAmount": "Eligible Amount",
		"excludedAmount": "Excluded Amount",
        "deductibleAmount": "Deductible",
		"coinsurancePct": "Co-Insurance Percentage",
		"paidAmount": "Blue Cross Paid",
		"hspAmount": "Spending Account Paid"
	},
	"nA": "N/A",
	"modal": {
		"excludedTitle": "Not sure why some of your payment was excluded?",
		"excludedText": "In the Explanation of Benefits under each claim you can find clarification that outlines why some of your claim was not covered or eligible for payment.",
		"coinsurancePctTitle": "What is co-insurance percentage?",
		"coinsurancePctText": "Your co-insurance percentage is the percentage of the claim that your plan will cover. For example, if you submit a claim and Blue Cross will cover 80% of costs associated, your co-insurance percentage will say: 80%."
	}
  },
  "fr": {
	"benefitDescriptionHeader" : "Description de la garantie",
	"adjustmentDescriptionHeader": "Description du rajustement",
	"column": {
		"serviceDate": "Date de service",
		"submittedAmount": "Montant soumis",
		"eligibleAmount": "Montant admissible",
		"excludedAmount": "Montant exclu",
        "deductibleAmount": "Franchise",
		"coinsurancePct": "Pourcentage de coassurance",
		"paidAmount": "Croix Bleue a payé",
		"hspAmount": "Payé par le compte Gestion-santé"
	},
	"nA": "S. O.",
	"modal": {
		"excludedTitle": "Pourquoi une partie de votre paiement est-elle exclue?",
		"excludedText": "Dans le message d’explication du remboursement qui figure sous chaque demande de règlement, vous trouverez les raisons pour lesquelles une partie de votre demande de règlement n’était pas admissible au remboursement.",
		"coinsurancePctTitle": "Qu’est-ce que le pourcentage de coassurance?",
		"coinsurancePctText": "Le pourcentage de coassurance est le pourcentage de la demande de règlement que votre régime rembourse. Par exemple, si vous soumettez une demande de règlement et que Croix Bleue rembourse 80 % des coûts qui lui sont associés, votre pourcentage de coassurance indique 80 %."
	}
  }
}
</i18n>
