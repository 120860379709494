<template>
	<BaseForm
		ref="premium-payments-search-date"
		v-slot="{ invalid }"
		:automation-id="getAutomationId('paymentDateSearchForm')"
		class="padding"
		@keydown.enter="onClick"
		@submit="onClick"
	>
		<BRow>
			<BCol cols="12" md="6" xl="5">
				<BaseDatePicker
					ref="fromDate"
					datepicker-id="payment-history-from-date-picker"
					:value="searchCriteria.fromDate"
					:label="$t('label.paymentFromDate')"
					:initial-date="searchCriteria.fromDate"
					:min-selectable-date="getMinSelectableDate()"
					:max-selectable-date="getMaxDate()"
					:min-error-date="getMinErrorDate()"
					:max-error-date="getMaxDate()"
					start-date
					:required="true"
					:required-error-msg="$t('error.fromdateRequired')"
					:before-today-and-after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
					:before-max-date-error-msg="$t('error.maxDate')"
					:after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
					pill
					is-left
					:automation-id="getAutomationId('start')"
					data-tracking-id="premium-payment-statements-form-from-date-picker"
					@input="updateFromDate"
				>
					<template #suffix>
						<span class="suffix-text ml-2">{{ $t('label.dateSuffix') }}</span>
					</template>
				</BaseDatePicker>
			</BCol>
			<BCol cols="12" md="6" xl="5">
				<BaseDatePicker
					ref="toDate"
					datepicker-id="payment-history-to-date-picker"
					:value="searchCriteria.toDate"
					:label="$t('label.paymentToDate')"
					:min-selectable-date="getMinSelectableDate()"
					:max-selectable-date="getMaxDate()"
					:min-error-date="getMinErrorDate()"
					:max-error-date="getMaxDate()"
					:start-date="false"
					:required="true"
					:from-date="searchCriteria.fromDate"
					:required-error-msg="$t('error.todateRequired')"
					:before-today-and-after-min-date-error-msg="$t('error.beforeTodayAndAfterMinDateError')"
					:before-max-date-error-msg="$t('error.maxDate')"
					pill
					is-left
					:automation-id="getAutomationId('end')"
					@input="updateToDate"
				>
					<template #suffix>
						<span class="suffix-text ml-2">{{ $t('label.dateSuffix') }}</span>
					</template>
				</BaseDatePicker></BCol
			>
		</BRow>
		<BRow>
			<BCol>
				<BaseButton
					class="search-button m-0"
					variant="primary"
					:disabled="buttonDisabled || invalid"
					:label="$t('button.search')"
					pill
					:automation-id="getAutomationId('searchByDate')"
					type="submit"
				></BaseButton>
			</BCol>
		</BRow>
	</BaseForm>
</template>

<script>
import Vue from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseForm from '@/components/common/base/BaseForm';
import PaymentHistory from '@/models/PaymentHistory.js';
import { format } from 'date-fns';

@Component({
	name: 'StatementSearchFields',
	components: {
		BRow,
		BCol,
		BaseButton,
		BaseDatePicker,
		BaseForm
	},
	mixins: [IdMixin]
})
export default class StatementSearchFields extends Vue {
	buttonDisabled = false;
	searchCriteria = {
		fromDate: format(
			new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
			'yyyy-MM-dd'
		),
		toDate: this.getMaxDate()
	};

	async beforeMount() {
		var tempSearchCriteria =
			(await PaymentHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
		if (tempSearchCriteria?.toDate) {
			this.searchCriteria = tempSearchCriteria;
			this.updateToDate(this.searchCriteria.toDate);
			this.updateFromDate(this.searchCriteria.fromDate);
		}
		this.isButtonDisabled();
	}
	/**
	 * get the minimum date a member could choose. This is 2 years before todays date.
	 */
	getMinSelectableDate() {
		return format(new Date(new Date().setFullYear(new Date().getFullYear() - 2)), 'yyyy-MM-dd');
	}

	/**
	 * get the minimum date a member could choose. This is 2 years before todays date.
	 */
	getMinErrorDate() {
		return format(new Date(new Date().setFullYear(new Date().getFullYear() - 2)), 'yyyy-MM-dd');
	}

	/**
	 * get the max date which is today.
	 */
	getMaxDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	/**
	 * emit click event
	 */
	async onClick() {
		this.$emit('click', this.searchCriteria);
	}

	/**
	 * Update from date
	 * @param {string} fromDate
	 */
	updateFromDate(fromDate) {
		if (fromDate) {
			if (this.isValidDate(fromDate)) {
				this.searchCriteria.fromDate = format(
					new Date(fromDate.split('-')[0], fromDate.split('-')[1] - 1, fromDate.split('-')[2]),
					'yyyy-MM-dd'
				);
			} else {
				if (fromDate.includes('-')) {
					var year = fromDate.split('-')[0];
					var month = fromDate.split('-')[1];
					var day = fromDate.split('-')[2];
					this.searchCriteria.fromDate = `${day}/${month}/${year}`;
				} else {
					this.searchCriteria.fromDate = fromDate;
				}
			}
		} else {
			this.searchCriteria.fromDate = null;
		}
		this.isButtonDisabled();
	}

	/**
	 * Update to date
	 * @param {string} to date
	 */
	updateToDate(toDate) {
		if (toDate) {
			if (this.isValidDate(toDate)) {
				this.searchCriteria.toDate = format(
					new Date(toDate.split('-')[0], toDate.split('-')[1] - 1, toDate.split('-')[2]),
					'yyyy-MM-dd'
				);
			} else {
				if (toDate.includes('-')) {
					var year = toDate.split('-')[0];
					var month = toDate.split('-')[1];
					var day = toDate.split('-')[2];
					this.searchCriteria.toDate = `${day}/${month}/${year}`;
				} else {
					this.searchCriteria.toDate = toDate;
				}
			}
		} else {
			this.searchCriteria.toDate = null;
		}
		this.isButtonDisabled();
	}

	isButtonDisabled() {
		if (
			!this.searchCriteria ||
			!this.searchCriteria.fromDate ||
			!this.searchCriteria.toDate ||
			!this.isValidDate(this.searchCriteria.fromDate) ||
			!this.isValidDate(this.searchCriteria.toDate)
		) {
			this.buttonDisabled = true;
		} else {
			this.buttonDisabled = false;
		}
	}
	isValidDate(selectedDate) {
		return !isNaN(new Date(selectedDate)) && selectedDate.length === 10;
	}
}
</script>

<style lang="scss" scoped>
.padding {
	padding: 30px;
}
.search-by-date {
	border-radius: 10px;
	border: 1px solid $light-grey;
	margin-left: 0em;
}

h2 {
	font-size: $body-large-bold-font-size;
	font-weight: $font-regular;
	margin-bottom: 0;
}

.suffix-text {
	font-size: $body-small-font-size;
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
}

.search-button {
	min-width: 100px;
	border: 1px solid #0079ad;
	border-radius: 30px;
	font-family: 'Josefin Sans', sans-serif;
	font-size: $body-regular-button-font-size;
	font-weight: normal;
	margin-left: 1em;
}
</style>

<i18n>
{
    "en": {
		"button": {
			"search": "Search"
		},
		"label": {
			"dateSuffix": "(DD/MM/YYYY)",
			"paymentFromDate": "Payment start date",
			"paymentToDate": "Payment end date"
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "This date is too far in the past. Our search results are limited to 24 months of history.",
			"fromdateRequired": "Enter a start date for your search.",
			"todateRequired": "Enter an end date for your search.",
			"maxDate": "The date you’ve entered is in the future. Please check the date and try again."
		}
    },
    "fr": {
		"button": {
			"search": "Rechercher"
		},
		"label": {
			"dateSuffix": "(JJ/MM/AAAA)",
			"paymentFromDate": "Date de début des paiements",
			"paymentToDate": "Date de fin des paiements"
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "La date est trop éloignée dans le passé. La période de recherche est limitée aux 24 derniers mois de l’historique.",
			"fromdateRequired": "Entrez une date de début pour votre recherche.",
			"todateRequired": "Entrez une date de fin pour votre recherche.",
			"maxDate": "La date que vous avez entrée est ultérieure à la date d’aujourd’hui. Veuillez corriger la date et essayer à nouveau."
		}
    }
}
</i18n>
