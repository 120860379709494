<template>
	<div v-if="claimAdjustments[0].paymentDetailLine">
		<h4 class="flex-grow-1 mb-1">{{ $t('claimAdjustments') }}</h4>
		<div
			v-for="(adjustmentLine, index) in claimAdjustments"
			:key="index"
			class="particpant-sections"
		>
			<PaymentDetailsTable
				:payment-line="adjustmentLine.paymentDetailLine"
				:benefit-description="benefitDescription(adjustmentLine)"
				is-claim-adjustment
			/>
			<ExplanationOfAdjustment
				v-if="explanationOfAdjustmentExists(adjustmentLine.explanationOfAdjustment)"
				:explanation-of-adjustment="adjustmentLine.explanationOfAdjustment"
			/>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import PaymentDetailsTable from '@/components/payment-history/PaymentDetailsTable.vue';
import ExplanationOfAdjustment from '@/components/payment-history/ExplanationOfAdjustment.vue';

// @vue/component
@Component({
	name: 'ClaimAdjustments',
	components: {
		PaymentDetailsTable,
		ExplanationOfAdjustment
	},
	mixins: [IdMixin],
	props: {
		claimAdjustments: {
			type: Array,
			required: true
		}
	}
})
export default class ClaimAdjustments extends Vue {
	get spendingAccountExists() {
		return this.claimAdjustments.spendingAccount;
	}

	benefitDescription(adjustmentLine) {
		if (adjustmentLine.benefitDescription) {
			return adjustmentLine.benefitDescription;
		} else {
			return this.$t('descNotDefined');
		}
	}

	explanationOfAdjustmentExists(explanationOfAdjustment) {
		return explanationOfAdjustment?.length > 0;
	}
}
</script>

<i18n>
{
	"en": {
		"claimAdjustments": "Claim Adjustment",
		"descNotDefined": "Description not defined"
	},
	"fr": {
		"claimAdjustments": "Rajustement d’une demande de règlement",
		"descNotDefined": "Description non définie"
	}
}
</i18n>
