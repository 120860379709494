<template>
	<div>
		<h2 v-if="firstOfList">{{ $t('calculationOfBenefits') }}</h2>
		<div class="d-flex flex-wrap align-items-baseline w-100 mb-2">
			<h4 class="flex-grow-1 mb-0">{{ calculationOfBenefits.participantName }}</h4>
			<span>{{ $t('dobLabel') }} {{ dobDateFormatted }}</span>
		</div>
		<div
			v-for="benefitLine in calculationOfBenefits.benefitLines"
			:key="benefitLine.benefitCode"
			class="particpant-sections"
		>
			<PaymentDetailsTable
				:payment-line="benefitLine.paymentDetailLine"
				:benefit-description="benefitLine.benefitDescription"
			/>
			<ExplanationOfBenefitsAndHSA
				v-if="explanationOfBenefitsExists(benefitLine.explanationOfBenefits)"
				:explanation-of-benefits-or-h-s-a="benefitLine.explanationOfBenefits"
				:hsa-exists="spendingAccountExists"
			/>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import PaymentDetailsTable from '@/components/payment-history/PaymentDetailsTable.vue';
import ExplanationOfBenefitsAndHSA from '@/components/payment-history/ExplanationOfBenefitsAndHSA.vue';

// @vue/component
@Component({
	name: 'CalculationOfBenefits',
	components: {
		PaymentDetailsTable,
		ExplanationOfBenefitsAndHSA
	},
	mixins: [IdMixin],
	props: {
		calculationOfBenefits: {
			type: Object,
			required: true
		},
		firstOfList: {
			type: Boolean,
			default: false
		}
	}
})
export default class CalculationOfBenefits extends Vue {
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	get spendingAccountExists() {
		return this.calculationOfBenefits.spendingAccount;
	}

	explanationOfBenefitsExists(eob) {
		return eob && eob.length > 0;
	}

	get dobDateFormatted() {
		var newDate =
			this.calculationOfBenefits.participantDob.slice(0, 4) +
			'/' +
			this.calculationOfBenefits.participantDob.slice(4, 6) +
			'/' +
			this.calculationOfBenefits.participantDob.slice(6);
		const dateProcessed = new Date(newDate);
		var options = { day: '2-digit', month: 'short', year: 'numeric' };

		if (this.isEnglish) {
			return dateProcessed.toLocaleDateString('en-GB', options);
		} else {
			return dateProcessed.toLocaleDateString('fr-FR', options);
		}
	}
}
</script>

<style lang="scss" scoped>
h2 {
	font-size: 24px;
}
</style>

<i18n>
{
	"en": {
		"calculationOfBenefits": "Calculation of Benefits",
		"dobLabel": "Date of birth:"
	},
	"fr": {
		"calculationOfBenefits": "Calcul des prestations",
		"dobLabel": "Date de naissance :"
	}
}
</i18n>
